import { Box, Button, Switch, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React, { ReactNode } from "react";
import copy from "copy-to-clipboard";
import { useDispatch } from "react-redux";
import { setSnackbarOpen } from "../../../state/snackbarSlice";
import { useExtensionsGetExtensionsGetQuery } from "../../../state/layerApi";

function BannerEmbed({
  darkMode,
  extensionUrl,
}: {
  darkMode: boolean;
  extensionUrl: string;
}) {
  return (
    <a
      href={extensionUrl}
      style={{ textDecoration: "none" }}
      target="_blank"
      rel="noreferrer"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
          px: 2,
          py: 1,
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 1,
          bgcolor: darkMode ? "#202020" : "#fff",
          color: darkMode ? "#fff" : "#333",
        }}
      >
        <img
          src="https://code.visualstudio.com/assets/images/code-stable.png"
          alt="VS Code Logo"
          style={{ width: "40px", height: "40px" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
            Download our VS Code Integration Expert
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            Powered by <a href="https://buildwithlayer.com">Layer</a>
          </Typography>
        </Box>
      </Box>
    </a>
  );
}

function BadgeEmbed({
  darkMode,
  extensionUrl,
}: {
  darkMode: boolean;
  extensionUrl: string;
}) {
  return (
    <a
      href={extensionUrl}
      style={{ textDecoration: "none" }}
      target="_blank"
      rel="noreferrer"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
          p: 3,
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 1,
          bgcolor: darkMode ? "#202020" : "#fff",
          color: darkMode ? "#fff" : "#333",
        }}
      >
        <img
          src="https://code.visualstudio.com/assets/images/code-stable.png"
          alt="VS Code Logo"
          style={{ width: "72px", height: "72px" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            gap: 1,
          }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
            Download our VS Code
            <br />
            Integration Expert
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            Powered by <a href="https://buildwithlayer.com">Layer</a>
          </Typography>
        </Box>
      </Box>
    </a>
  );
}

function EmbedCode({
  children,
  copyText,
  darkMode,
  setDarkMode,
}: {
  children: ReactNode;
  copyText: string;
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}) {
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        borderRadius: "4px",
        border: "1px solid",
        borderColor: "divider",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
          py: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography>Light</Typography>
          <Switch
            onChange={() => {
              setDarkMode(!darkMode);
            }}
          />
          <Typography>Dark</Typography>
        </Box>
        <Button
          variant="text"
          onClick={() => {
            copy(copyText);
            dispatch(
              setSnackbarOpen({ open: true, message: "Embed Code Copied" }),
            );
          }}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ContentCopyIcon />
          Copy Embed Code
        </Button>
      </Box>
      <pre
        style={{
          overflow: "scroll",
          margin: 0,
          borderRadius: "0 0 4px 4px",
        }}
      >
        <code>{children}</code>
      </pre>
    </Box>
  );
}

const EmbeddableLinks = () => {
  const [bannerDarkMode, setBannerDarkMode] = React.useState(false);
  const [badgeDarkMode, setBadgeDarkMode] = React.useState(false);

  const { data: extensions } = useExtensionsGetExtensionsGetQuery();
  const extension = extensions ? extensions[0] : undefined;
  const extensionUrl = extension?.marketplace_url;

  const lightModeBannerCopyText =
    '<style> @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap"); </style> <a href="' +
    extensionUrl +
    '" target="_blank" style="text-decoration: none; color: #333" > <div style=" width: 405px; display: flex; flex-direction: row; align-items: center; gap: 16px; padding: 8px 16px; border: 1px solid #e0e0e0; border-radius: 8px; background-color: #fff; font-family: Manrope, sans-serif; " > <img src="https://code.visualstudio.com/assets/images/code-stable.png" alt="VS Code Logo" style="width: 40px; height: 40px" /> <div style="display: flex; flex-direction: column"> <h3 style="font-size: 18px; font-weight: 500; margin: 0"> Download our VS Code Integration Expert</h3> <p style="font-size: 14px; margin: 0">Powered by <a href="https://buildwithlayer.com" target="_blank" style="color: #7b65ff">Layer</a> </p> </div> </div> </a>';
  const darkModeBannerCopyText =
    '<style> @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap"); </style> <a href="' +
    extensionUrl +
    '" target="_blank" style="text-decoration: none; color: #fff" > <div style=" width: 405px; display: flex; flex-direction: row; align-items: center; gap: 16px; padding: 8px 16px; border: 1px solid #3B3B3B; border-radius: 8px; background-color: #202020; font-family: Manrope, sans-serif; " > <img src="https://code.visualstudio.com/assets/images/code-stable.png" alt="VS Code Logo" style="width: 40px; height: 40px" /> <div style="display: flex; flex-direction: column"> <h3 style="font-size: 18px; font-weight: 500; margin: 0"> Download our VS Code Integration Expert</h3> <p style="font-size: 14px; margin: 0">Powered by <a href="https://buildwithlayer.com" target="_blank" style="color: #7b65ff">Layer</a> </p> </div> </div> </a>';
  const lightModeBadgeCopyText =
    '<style> @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap"); </style> <a href="' +
    extensionUrl +
    '" target="_blank" style="text-decoration: none; color: #333" > <div style=" width: 200px; display: flex; flex-direction: column; align-items: center; gap: 24px; padding: 24px; border: 1px solid #e0e0e0; border-radius: 8px; background-color: #fff; font-family: Manrope, sans-serif; " > <img src="https://code.visualstudio.com/assets/images/code-stable.png" alt="VS Code Logo" style="width: 72px; height: 72px" /> <div style="display: flex; flex-direction: column; gap: 8px; text-align: center"> <h3 style="font-size: 18px; font-weight: 500; margin: 0"> Download our VS Code<br/>Integration Expert</h3> <p style="font-size: 14px; margin: 0">Powered by <a href="https://buildwithlayer.com" target="_blank" style="color: #7b65ff">Layer</a> </p> </div> </div> </a>';
  const darkModeBadgeCopyText =
    '<style> @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap"); </style> <a href="' +
    extensionUrl +
    '" target="_blank" style="text-decoration: none; color: #fff" > <div style=" width: 200px; display: flex; flex-direction: column; align-items: center; gap: 24px; padding: 24px; border: 1px solid #3B3B3B; border-radius: 8px; background-color: #202020; font-family: Manrope, sans-serif; " > <img src="https://code.visualstudio.com/assets/images/code-stable.png" alt="VS Code Logo" style="width: 72px; height: 72px" /> <div style="display: flex; flex-direction: column; gap: 8px; text-align: center"> <h3 style="font-size: 18px; font-weight: 500; margin: 0"> Download our VS Code<br/>Integration Expert</h3> <p style="font-size: 14px; margin: 0">Powered by <a href="https://buildwithlayer.com" target="_blank" style="color: #7b65ff">Layer</a> </p> </div> </div> </a>';

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
        alignItems: "start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxWidth: "100%",
        }}
      >
        <Typography sx={{ fontWeight: "500" }}>
          Banner-Style Embeddable Link
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <BannerEmbed
            darkMode={bannerDarkMode}
            extensionUrl={extensionUrl ?? ""}
          />
        </Box>
        <EmbedCode
          darkMode={bannerDarkMode}
          setDarkMode={setBannerDarkMode}
          copyText={
            bannerDarkMode ? darkModeBannerCopyText : lightModeBannerCopyText
          }
        >
          {`<style>`}
          <br />
          {`  @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");`}
          <br />
          {`</style>`}
          <br />
          {bannerDarkMode
            ? `<a href="` +
              extensionUrl +
              `" target="_blank" style="text-decoration: none; color: #fff" >`
            : `<a href="` +
              extensionUrl +
              `" target="_blank" style="text-decoration: none; color: #333" >`}
          <br />
          {`  <div style="`}
          <br />
          {`    width: 405px;`}
          <br />
          {`    display: flex;`}
          <br />
          {`    flex-direction: row;`}
          <br />
          {`    align-items: center;`}
          <br />
          {`    gap: 16px;`}
          <br />
          {`    padding: 8px 16px;`}
          <br />
          {bannerDarkMode
            ? `    border: 1px solid #3B3B3B;`
            : `    border: 1px solid #e0e0e0;`}
          <br />
          {`    border-radius: 8px;`}
          <br />
          {bannerDarkMode
            ? `    background-color: #202020;`
            : `    background-color: #fff;`}
          <br />
          {`    font-family: Manrope, sans-serif;`}
          <br />
          {`  ">`}
          <br />
          {`    <img src="https://code.visualstudio.com/assets/images/code-stable.png" alt="VS Code Logo" style="width: 40px; height: 40px" />`}
          <br />
          {`    <div style="display: flex; flex-direction: column">`}
          <br />
          {`      <h3 style="font-size: 18px; font-weight: 500; margin: 0">`}
          <br />
          {`        Download our VS Code Integration Expert`}
          <br />
          {`      </h3>`}
          <br />
          {`      <p style="font-size: 14px; margin: 0">`}
          <br />
          {`        Powered by <a href="https://buildwithlayer.com" target="_blank" style="color: #7b65ff">Layer</a>`}
          <br />
          {`      </p>`}
          <br />
          {`    </div>`}
          <br />
          {`  </div>`}
          <br />
          {`</a>`}
        </EmbedCode>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxWidth: "100%",
        }}
      >
        <Typography sx={{ fontWeight: "500" }}>
          Badge-Style Embeddable Link
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <BadgeEmbed
            darkMode={badgeDarkMode}
            extensionUrl={extensionUrl ?? ""}
          />
        </Box>
        <EmbedCode
          darkMode={badgeDarkMode}
          setDarkMode={setBadgeDarkMode}
          copyText={
            badgeDarkMode ? darkModeBadgeCopyText : lightModeBadgeCopyText
          }
        >
          {`<style>`}
          <br />
          {`  @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");`}
          <br />
          {`</style>`}
          <br />
          {badgeDarkMode
            ? `<a href="` +
              extensionUrl +
              `" target="_blank" style="text-decoration: none; color: #fff" >`
            : `<a href="` +
              extensionUrl +
              `" target="_blank" style="text-decoration: none; color: #333" >`}
          <br />
          {`  <div style="`}
          <br />
          {`    width: 200px;`}
          <br />
          {`    display: flex;`}
          <br />
          {`    flex-direction: column;`}
          <br />
          {`    align-items: center;`}
          <br />
          {`    gap: 24px;`}
          <br />
          {`    padding: 24px;`}
          <br />
          {badgeDarkMode
            ? `    border: 1px solid #3B3B3B;`
            : `    border: 1px solid #e0e0e0;`}
          <br />
          {`    border-radius: 8px;`}
          <br />
          {badgeDarkMode
            ? `    background-color: #202020;`
            : `    background-color: #fff;`}
          <br />
          {`    font-family: Manrope, sans-serif;`}
          <br />
          {`  ">`}
          <br />
          {`    <img src="https://code.visualstudio.com/assets/images/code-stable.png" alt="VS Code Logo" style="width: 72px; height: 72px" />`}
          <br />
          {`    <div style="display: flex; flex-direction: column; gap: 8px; text-align: center">`}
          <br />
          {`      <h3 style="font-size: 18px; font-weight: 500; margin: 0">`}
          <br />
          {`        Download our VS Code Integration Expert`}
          <br />
          {`      </h3>`}
          <br />
          {`      <p style="font-size: 14px; margin: 0">`}
          <br />
          {`        Powered by <a href="https://buildwithlayer.com" target="_blank" style="color: #7b65ff">Layer</a>`}
          <br />
          {`      </p>`}
          <br />
          {`    </div>`}
          <br />
          {`  </div>`}
          <br />
          {`</a>`}
        </EmbedCode>
      </Box>
    </Box>
  );
};

export default EmbeddableLinks;
