import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import React, { useEffect } from "react";

interface IAnalyticsBarChart {
  chartData: {
    data: { date: Date; value: number; duplicateId?: string }[];
    name?: string;
    type?: "bar";
  }[];
  label?: string;
}

const AnalyticsBarChart = ({ chartData, label }: IAnalyticsBarChart) => {
  const [timeRange, setTimeRange] = React.useState("7d");
  const [xLabels, setXLabels] = React.useState<string[]>([]);
  const [data, setData] = React.useState(
    chartData.map((series) => ({
      ...series,
      data: series.data.map((item) => item.value),
    })),
  );

  const currentDate = () => {
    return new Date();
  };

  const getXHoursAgo = (hours: number) => {
    return new Date(
      new Date(
        currentDate().setHours(currentDate().getHours() - hours),
      ).setMinutes(0, 0, 0),
    );
  };

  const getXDaysAgo = (days: number) => {
    return new Date(
      new Date(currentDate().setDate(currentDate().getDate() - days)).setHours(
        0,
        0,
        0,
        0,
      ),
    );
  };

  const getXMonthsAgo = (months: number) => {
    return new Date(
      new Date(
        currentDate().setMonth(currentDate().getMonth() - months),
      ).setDate(1),
    );
  };

  const updateXLabels = (
    { hour, minute, month, day, hour12 }: Intl.DateTimeFormatOptions,
    xLabelDateRanges: Date[][],
  ) => {
    setXLabels(
      xLabelDateRanges.map((dateRange) =>
        dateRange[0].toLocaleString("default", {
          hour,
          minute,
          month,
          day,
          hour12,
        }),
      ),
    );
  };

  const filterData = (timeRange: string) => {
    let xLabelDateRanges: Date[][] = [];
    let filteredData: { data: number[]; label?: string; id?: string }[] = [];
    switch (timeRange) {
      case "24h":
        xLabelDateRanges = [];
        for (let i = 23; i >= 1; i--) {
          xLabelDateRanges.push([getXHoursAgo(i), getXHoursAgo(i - 1)]);
        }
        xLabelDateRanges.push([getXHoursAgo(0), currentDate()]);
        updateXLabels(
          { hour: "numeric", minute: "numeric", hour12: false },
          xLabelDateRanges,
        );
        break;
      case "7d":
        xLabelDateRanges = [];
        for (let i = 6; i >= 1; i--) {
          xLabelDateRanges.push([getXDaysAgo(i), getXDaysAgo(i - 1)]);
        }
        xLabelDateRanges.push([getXDaysAgo(0), currentDate()]);
        updateXLabels({ month: "numeric", day: "numeric" }, xLabelDateRanges);
        break;
      case "30d":
        xLabelDateRanges = [];
        for (let i = 29; i >= 1; i--) {
          xLabelDateRanges.push([getXDaysAgo(i), getXDaysAgo(i - 1)]);
        }
        xLabelDateRanges.push([getXDaysAgo(0), currentDate()]);
        updateXLabels({ month: "numeric", day: "numeric" }, xLabelDateRanges);
        break;
      case "3m":
        xLabelDateRanges = [];
        for (let i = 2; i >= 1; i--) {
          xLabelDateRanges.push([getXMonthsAgo(i), getXMonthsAgo(i - 1)]);
        }
        xLabelDateRanges.push([getXMonthsAgo(0), currentDate()]);
        updateXLabels({ month: "short" }, xLabelDateRanges);
        break;
      case "6m":
        xLabelDateRanges = [];
        for (let i = 5; i >= 1; i--) {
          xLabelDateRanges.push([getXMonthsAgo(i), getXMonthsAgo(i - 1)]);
        }
        xLabelDateRanges.push([getXMonthsAgo(0), currentDate()]);
        updateXLabels({ month: "short" }, xLabelDateRanges);
        break;
      case "12m":
        xLabelDateRanges = [];
        for (let i = 11; i >= 1; i--) {
          xLabelDateRanges.push([getXMonthsAgo(i), getXMonthsAgo(i - 1)]);
        }
        xLabelDateRanges.push([getXMonthsAgo(0), currentDate()]);
        updateXLabels({ month: "short" }, xLabelDateRanges);
        break;
      default:
        setXLabels([]);
        break;
    }

    chartData.forEach((series) => {
      const timeRangeValueSums = xLabelDateRanges.map((dateRange) => {
        let addedIds: string[] = [];
        return series.data.reduce((acc, item) => {
          if (item.date >= dateRange[0] && item.date < dateRange[1]) {
            if (item.duplicateId && addedIds.includes(item.duplicateId)) {
              return acc;
            } else if (item.duplicateId) {
              addedIds.push(item.duplicateId);
            }
            return acc + item.value;
          }
          return acc;
        }, 0);
      });

      filteredData.push({
        ...series,
        data: timeRangeValueSums,
      });
    });
    setData(filteredData);
  };

  useEffect(() => {
    filterData(timeRange);
  }, [timeRange]);

  return (
    <Box
      sx={{
        borderRadius: 2,
        border: 1,
        borderColor: "divider",
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>{label || ""}</Typography>
        <FormControl size="small">
          <Select
            id="time-range-select"
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value)}
            sx={{ borderRadius: 100, fontSize: ".8rem" }}
          >
            <MenuItem value="24h">Last 24 hours</MenuItem>
            <MenuItem value="7d">Last 7 days</MenuItem>
            <MenuItem value="30d">Last 30 days</MenuItem>
            <MenuItem value="3m">Last 3 months</MenuItem>
            <MenuItem value="6m">Last 6 months</MenuItem>
            <MenuItem value="12m">Last 12 months</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <BarChart
        series={data}
        height={290}
        xAxis={[{ data: xLabels, scaleType: "band" }]}
        margin={{ top: 50, bottom: 30, left: 40, right: 10 }}
        grid={{ horizontal: true }}
      />
    </Box>
  );
};

export default AnalyticsBarChart;
