import { IPageTemplate } from "./PageTemplate";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Sources from "../components/dashboard/sources/Sources";
import HubIcon from "@mui/icons-material/Hub";
import VSCodeExtension from "../components/dashboard/deployments/VSCodeExtension";
import React from "react";
import Analytics from "../components/dashboard/analytics/Analytics";
import BarChartIcon from "@mui/icons-material/BarChart";
import WebWidget from "../components/dashboard/deployments/WebWidget";

const pageProps: (IPageTemplate & {
  path: string;
  pageTitle: string;
  parent?: string;
})[] = [
  {
    name: "Sources",
    description:
      "Add & manage the sources Integration Expert references to assist developers integrating Plaid.",
    icon: <UploadFileIcon />,
    children: <Sources />,
    path: "/sources",
    pageTitle: "Layer - Sources",
  },
  {
    name: "Analytics",
    description:
      "Get analytics on how developers are using your Integration Expert.",
    icon: <BarChartIcon />,
    children: <Analytics />,
    path: "/analytics",
    pageTitle: "Layer - Analytics",
  },
  {
    name: "VS Code Extension",
    description:
      "Adjust how your Integration Expert extension will show up in VSCode’s marketplace.",
    icon: <HubIcon />,
    children: <VSCodeExtension />,
    path: "/deployments/vscode",
    pageTitle: "Layer - VS Code Extension Deployment",
    parent: "Deployments",
  },
  {
    name: "Website Widget",
    description: "Adjust how your website widget appears.",
    icon: <HubIcon />,
    children: <WebWidget />,
    path: "/deployments/web-widget",
    pageTitle: "Layer - Website Widget Deployment",
    parent: "Deployments",
  },
];

export default pageProps;
