import { Box, Button, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React, { useEffect, useMemo } from "react";
import PageTemplate from "../../../shared/PageTemplate";
import HubIcon from "@mui/icons-material/Hub";
import copy from "copy-to-clipboard";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";
import {
  useCustomersGetCustomerGetQuery,
  useWebWidgetsGetWebWidgetsGetQuery,
  useWebWidgetsUpdateWidgetPutMutation,
} from "../../../state/layerApi";
import { useLazyDownloadWebWidgetLogoQuery } from "../../../state/emptyApi";
import Form, { Field } from "../../../shared/Form";
import { Chat } from "@buildwithlayer/embeddable-widget";

// --------------- Types ---------------
type FieldValues = {
  productName?: string;
  productLogo?: string;
  primaryColor?: string;
};

// --------------- Components ---------------
const WebWidget = () => {
  const theme = useTheme();
  const grey100 = theme.palette.grey[100];

  const { addMessage } = useSnackbarContext();

  const [tabValue, setTabValue] = React.useState("Preview");
  const [editMode, setEditMode] = React.useState(false);

  const { data: customer } = useCustomersGetCustomerGetQuery();
  const apiKey = customer?.api_key;

  const [scriptTag, setScriptTag] = React.useState("");
  const [primaryColor, setPrimaryColor] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [productName, setProductName] = React.useState("");

  const { data: widgets } = useWebWidgetsGetWebWidgetsGetQuery();
  const widget = useMemo(() => widgets?.[0], [widgets]);
  const [downloadLogo] = useLazyDownloadWebWidgetLogoQuery();
  const [updateWidget, { isLoading: isSubmitLoading }] =
    useWebWidgetsUpdateWidgetPutMutation();

  useEffect(() => {
    if (widget === undefined) return;

    setPrimaryColor(widget.primary_color);
    setProductName(widget.product_name);

    downloadLogo({ webWidgetId: widget.id })
      .unwrap()
      .then((data) => {
        if (typeof data === "string") {
          setLogo(data);
        } else if (data && typeof data === "object" && "data" in data) {
          setLogo(data.data as string);
        } else {
          console.error("Unexpected data format:", data);
        }
      })
      .catch((error) => {
        console.error("Error downloading logo:", error);
      });
  }, [widget, downloadLogo, setPrimaryColor, setLogo]);

  useEffect(() => {
    if (apiKey) {
      setScriptTag(`
        <script type="module"
          crossorigin
          apiKey="${apiKey}"
          ${primaryColor !== "" ? `primaryColor="${primaryColor}"` : ""}
          src="https://storage.googleapis.com/generic-assets/buildwithlayer-widget.js">
        </script>`);
    }
  }, [apiKey, primaryColor]);

  const formFields: Field[] = [
    {
      fieldName: "productName",
      fieldLabel: "Product Name",
      fieldType: "text",
      fieldRequired: true,
      fieldDefaultValue: productName,
    },
    {
      fieldName: "productLogo",
      fieldLabel: "Logo",
      fieldType: "file",
      fieldRequired: false,
      fieldAcceptedFileTypes: ["png", "jpg"],
      fieldImageMinSize: { width: 128, height: 128 },
      fieldImageCrop: {
        aspectRatio: 1,
        minSize: { width: 128, height: 128 },
        rescaleImageWidth: 256,
      },
    },
    {
      fieldName: "primaryColor",
      fieldLabel: "Primary Color",
      fieldType: "text",
      fieldRequired: true,
      fieldDefaultValue: primaryColor,
      fieldHelperText:
        "Hex code for your company's primary color (e.g. #FF0000)",
    },
  ];

  async function handleFormSubmit(values: FieldValues) {
    if (widget === undefined) {
      addMessage("Widget not found", "error", 3);
      return;
    }

    // Create a new FormData instance
    let formData = new FormData();

    // Append fields to formData (required by form)
    formData.append("product_name", values.productName!);
    formData.append("primary_color", values.primaryColor!);

    if (values.productLogo) {
      formData.append("logo_file", values.productLogo);
    }

    updateWidget({
      webWidgetId: widget.id,
      // @ts-ignore
      webWidgetUpdate: formData,
    }).then(() => {
      addMessage("Widget updated", "success", 3);
      setEditMode(false);
    });
  }

  return (
    <PageTemplate
      name={"Website Widget"}
      description={"Adjust how your website widget appears."}
      icon={<HubIcon />}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: 1,
          border: 1,
          borderColor: "divider",
        }}
      >
        {!editMode && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "stretch",
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={(_, newValue) => setTabValue(newValue)}
              >
                <Tab label="Preview" value="Preview" />
                <Tab label="Installation" value="Installation" />
              </Tabs>
              <Button
                variant="contained"
                onClick={() => {
                  setEditMode(true);
                }}
                sx={{
                  px: 2,
                  py: 1,
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  my: 1.5,
                  mx: 2,
                }}
              >
                <EditIcon />
                Edit
              </Button>
            </Box>
            <Box>
              {tabValue === "Preview" && (
                <div
                  style={{
                    position: "relative",
                    height: "600px",
                    width: "100%",
                  }}
                >
                  <Chat
                    product={{
                      name: widget?.product_name || "",
                      logoUrl: logo,
                      apiUrl: process.env.REACT_APP_LAYER_URL || "",
                      marketplaceUrl:
                        "https://marketplace.visualstudio.com/items?itemName=buildwithlayer.django-integration-expert-Gus30asdf",
                      primaryColor: widget?.primary_color || "#000000",
                      apiKey: apiKey || "",
                    }}
                    paletteMode="light"
                    walkthroughEnabled={false}
                    inContainer
                  />
                </div>
              )}
              {tabValue === "Installation" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    p: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <p>
                      Copy this script and paste it before the closing&nbsp;
                      <code
                        style={{
                          padding: "2px",
                          margin: "0 2px",
                          borderRadius: "4px",
                          backgroundColor: grey100,
                        }}
                      >
                        &lt;/head&gt;
                      </code>{" "}
                      tag of your website.
                    </p>
                    <Button
                      size="small"
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        px: 1.5,
                        py: 1,
                      }}
                      onClick={() => {
                        copy(scriptTag);
                        addMessage("Script Copied to Clipboard", "success", 3);
                      }}
                    >
                      <ContentCopyIcon fontSize="small" />
                      Copy
                    </Button>
                  </Box>
                  <code
                    style={{
                      padding: "16px",
                      margin: "0 2px",
                      borderRadius: "4px",
                      backgroundColor: grey100,
                      overflow: "scroll",
                    }}
                  >
                    {scriptTag}
                  </code>
                </Box>
              )}
            </Box>
          </>
        )}
        {editMode && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" sx={{ flexGrow: 1 }}>
                Edit VS Code Extension
              </Typography>
              <IconButton onClick={() => setEditMode(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Form
              fields={formFields}
              handleFormSubmit={handleFormSubmit}
              submitButtonLabel="Save Changes"
              submitButtonLoading={isSubmitLoading}
            />
          </Box>
        )}
      </Box>
    </PageTemplate>
  );
};

export default WebWidget;
