import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  DocumentSource,
  useSourcesDeleteSourceDeleteMutation,
} from "../../../../state/layerApi";
import { formatDateTime } from "../../../../utils/dateTime";
import DownloadSourceButton from "./DownloadSourceButton";
import { Button, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../state/store";
import { setEditSourceModalOpen } from "../../../../state/sources/editSourceModalOpenSlice";
import { setSnackbarOpen } from "../../../../state/snackbarSlice";
import DeleteConfirmationModal from "../../../../shared/DeleteConfirmationModal";
import {Source} from "../../../../shared/types";

function DeleteButton({ source }: { source: Source }) {
  const dispatch = useDispatch<AppDispatch>();
  const [deleteSource, { isLoading }] = useSourcesDeleteSourceDeleteMutation();

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    React.useState(false);

  const handleDelete = () => {
    deleteSource({ sourceId: source.id }).then(() => {
      dispatch(setSnackbarOpen({ open: true, message: "Source deleted" }));
    });
  };

  const onClick = () => {
    setDeleteConfirmationModalOpen(true);
  };

  return (
    <>
      <IconButton onClick={onClick} disabled={isLoading}>
        <DeleteIcon sx={{ width: "20px", height: "20px" }} />
      </IconButton>
      <DeleteConfirmationModal
        modalOpen={deleteConfirmationModalOpen}
        itemName={source.name}
        setOpen={setDeleteConfirmationModalOpen}
        handleDelete={handleDelete}
      />
    </>
  );
}

function EditButton({ source }: { source: Source }) {
  const dispatch = useDispatch<AppDispatch>();

  const onClick = () => {
    dispatch(
      setEditSourceModalOpen({
        open: true,
        selectedRowId: source.id,
      }),
    );
  };

  return (
    <IconButton onClick={onClick} disabled={source.type === "website"}>
      <EditIcon sx={{ width: "20px", height: "20px" }} />
    </IconButton>
  );
}

export function SourcesTableColumns(): GridColDef[] {
  return [
    {
      field: "type",
      headerName: "Type",
      type: "string",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        const source = params.row as Source;
        if (source.type === "website") {
          return "🔗\xa0\xa0Website";
        } else if (source.type === "document") {
          return "📄\xa0\xa0Document";
        } else if (source.type === "suggestion") {
          return "❓\xa0\xa0Suggestion";
        }
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
    },
    {
      field: "created_at",
      headerName: "Created On",
      type: "dateTime",
      valueGetter: (value) => value && new Date(value + "Z"),
      valueFormatter: (value?: Date) => {
        if (value == null) {
          return "";
        }
        return formatDateTime(value);
      },
      flex: 1,
    },
    {
      field: "updated_at",
      headerName: "Updated On",
      type: "dateTime",
      valueGetter: (value) => value && new Date(value + "Z"),
      valueFormatter: (value?: Date) => {
        if (value == null) {
          return "";
        }
        return formatDateTime(value);
      },
      flex: 1,
    },
    {
      field: "collection_id",
      headerName: "Collection",
      type: "string",
      sortable: false,
      hideable: false,
      flex: 1,
    },
    {
      field: "content",
      headerName: "Content",
      type: "string",
      sortable: false,
      filterable: false,
      flex: 2,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        const source = params.row as Source;
        if (source.url) {
          return (
            <a href={source.url} target="_blank" rel="noreferrer">
              {source.url}
            </a>
          );
        } else if (source.type === "document") {
          return <DownloadSourceButton source={source as DocumentSource} />;
        } else if (source.type === "suggestion") {
          return (
            <>
              <Button
                variant="text"
                size="small"
                sx={{ p: 0, textAlign: "left" }}
                onClick={() => {}}
              >
                Suggestion
              </Button>
            </>
          );
        } else {
          return <>{params.value}</>;
        }
      },
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      resizable: false,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        const source = params.row as Source;
        if (source.type === "website") {
          return (
            <>
              <Tooltip
                title="Website sources cannot be edited. Please delete and add a new source."
                disableInteractive
                placement="top"
              >
                <span>
                  <IconButton disabled onClick={() => {}}>
                    <EditIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                </span>
              </Tooltip>
              <DeleteButton source={source} />
            </>
          );
        } else {
          return (
            <>
              <EditButton source={source} />
              <DeleteButton source={source} />
            </>
          );
        }
      },
      width: 100,
    },
  ];
}
