import { Box } from "@mui/material";
import SourcesTable from "./table/SourcesTable";
import PageTemplate from "../../../shared/PageTemplate";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const Sources = () => {
  return (
    <PageTemplate
      name={"Sources"}
      description={
        "Add & manage the sources Integration Expert references to assist developers during integration."
      }
      icon={<UploadFileIcon />}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <SourcesTable />
        </Box>
      </Box>
    </PageTemplate>
  );
};

export default Sources;
