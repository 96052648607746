import * as React from "react";
import { Field, Form } from "../../shared/Form";
import { FieldValues } from "react-hook-form";
import { useLazyCustomersCheckNameAvailabilityGetQuery } from "../../state/layerApi";
import { Box } from "@mui/material";
import { useSnackbarContext } from "../../contexts/SnackbarContext";

interface CompanyNameFormProps {
  setCompanyName: (name: string) => void;
}

const CompanyNameForm = ({ setCompanyName }: CompanyNameFormProps) => {
  const { addMessage } = useSnackbarContext();
  const [localCompanyName, setLocalCompanyName] = React.useState("");
  const [companyNameAvailable, setCompanyNameAvailable] = React.useState<
    boolean | undefined
  >(undefined);

  const [checkName, { isLoading }] =
    useLazyCustomersCheckNameAvailabilityGetQuery();

  const companyNameFields: Field[] = [
    {
      fieldName: "companyName",
      fieldLabel: "Company Name",
      fieldRequired: true,
      fieldType: "text",
    },
  ];

  function handleNext({ companyName }: FieldValues) {
    if (!companyName) return;
    setLocalCompanyName(companyName);
    checkName({ companyName: companyName })
      .unwrap()
      .then((response) => {
        if (response.available) {
          setCompanyNameAvailable(true);
        } else {
          addMessage("Company name already exists.", "error", 60);
          setCompanyNameAvailable(false);
        }
      });
  }

  React.useEffect(() => {
    if (companyNameAvailable) {
      setCompanyName(localCompanyName);
    }
  }, [companyNameAvailable, localCompanyName]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Form
        fields={companyNameFields}
        submitButtonLabel="Next"
        submitButtonLoading={isLoading}
        handleFormSubmit={handleNext}
      />
    </Box>
  );
};

export default CompanyNameForm;
