import * as React from "react";
import {Button} from "@mui/material";
import {DocumentSource} from "../../../../state/layerApi";
import {useAuthContext} from "../../../../contexts/AuthContext";

export default function DownloadSourceButton({source}: {source: DocumentSource}) {
    const user = useAuthContext();
    const onClick = async () => {
        const url = `${process.env.REACT_APP_LAYER_URL}sources/${source.id}/download`;
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/octet-stream',
                'Firebase-Auth': await user?.getIdToken() || "",
            },
        };

        fetch(url, options)
            .then(response => response.blob())
            .then(blobLike => {
                const objectUrl = window.URL.createObjectURL(blobLike);
                const link = document.createElement('a');
                link.download = source.filename;
                link.href = objectUrl;
                link.click();
                window.URL.revokeObjectURL(objectUrl);
            })
            .catch(err => console.log(err));
    }

    return (
        <Button
            variant="text"
            size="small"
            sx={{p: 0, textAlign: "left"}}
            onClick={onClick}
        >
            {source.filename}
        </Button>
    );
}