import * as React from "react";
import { useLocation } from "react-router-dom";
import Sources from "./sources/Sources";
import pageProps from "../../shared/Pages";

const Dashboard = () => {
  const pagePaths = pageProps.map((props) => props.path);

  const location = useLocation();
  const [pagePath, setPagePath] = React.useState<string>(location.pathname);

  React.useEffect(() => {
    if (pagePaths.includes(location.pathname)) {
      setPagePath(location.pathname);
    } else {
      window.location.href = "/sources";
    }
  }, [location, setPagePath]);

  React.useEffect(() => {
    document.title =
      pageProps.find((props) => props.path === pagePath)?.pageTitle ||
      "Layer - Sources";
  }, [pagePath]);

  return (
    <>
      {pageProps.find((props) => props.path === pagePath)?.children || (
        <Sources />
      )}
    </>
  );
};

export default Dashboard;
