import { Box, Button, IconButton, Tab, Tabs, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PublishIcon from "@mui/icons-material/Publish";
import React, { useEffect } from "react";
import { Form, Field } from "../../../shared/Form";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {
  useExtensionsGetExtensionsGetQuery,
  useExtensionsListTemplatesGetQuery,
  useExtensionsUpdateExtensionPutMutation,
  useLazyExtensionsPublishExtensionGetQuery,
} from "../../../state/layerApi";
import { useLazyDownloadExtensionObjectQuery } from "../../../state/emptyApi";
import PageTemplate from "../../../shared/PageTemplate";
import HubIcon from "@mui/icons-material/Hub";
import EmbeddableLinks from "./EmbeddableLinks";
import { LoadingButton } from "@mui/lab";
import { useSnackbarContext } from "../../../contexts/SnackbarContext";

// --------------- Types ---------------
type FieldValues = {
  marketplaceName?: string;
  marketplaceDisplayName?: string;
  marketplaceDescription?: string;
  marketplaceIcon?: string;
  marketplaceREADME?: string;
  participantName?: string;
  participantDescription?: string;
};

// --------------- Helper Functions ---------------
function fieldValues() {
  return {
    marketplaceName: "",
    marketplaceDisplayName: "",
    marketplaceDescription: "",
    marketplaceREADME: "",
    marketplaceIcon: "",
    participantName: "",
    participantDescription: "",
  };
}

// --------------- Components ---------------
const VSCodeExtension = () => {
  const { data: extensions, refetch: refetchExtension } =
    useExtensionsGetExtensionsGetQuery();
  const extension = extensions ? extensions[0] : undefined;
  const [putExtension] = useExtensionsUpdateExtensionPutMutation();
  const [downloadObject] = useLazyDownloadExtensionObjectQuery();
  const [readme, setReadme] = React.useState<string>("");
  const [logo, setLogo] = React.useState<string>("");

  const [publishExtension, { isLoading }] =
    useLazyExtensionsPublishExtensionGetQuery();
  const { addMessage } = useSnackbarContext();

  const { data: templates } = useExtensionsListTemplatesGetQuery();

  useEffect(() => {
    if (extension === undefined) return;

    downloadObject({
      extensionId: extension.id,
      specialObjectName: "README.md",
    })
      .unwrap()
      .then((data) => setReadme(data as string));

    downloadObject({
      extensionId: extension.id,
      specialObjectName: "logo.png",
    })
      .unwrap()
      .then((data) => setLogo(data as string));
  }, [extension, setReadme, setLogo, downloadObject]);

  const [tabValue, setTabValue] = React.useState("Readme");
  const [submitButtonLoading, setSubmitButtonLoading] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [marketplaceListingDetails, setMarketplaceListingDetails] =
    React.useState<FieldValues>(fieldValues());

  const formFields: Field[] = [
    {
      fieldName: "marketplaceIcon",
      fieldLabel: "Extension Icon",
      fieldType: "file",
      fieldRequired: false,
      fieldAcceptedFileTypes: ["png", "jpg"],
      fieldImageMinSize: { width: 128, height: 128 },
      fieldImageCrop: {
        aspectRatio: 1,
        minSize: { width: 128, height: 128 },
        rescaleImageWidth: 256,
      },
    },
    {
      fieldName: "marketplaceDisplayName",
      fieldLabel: "Extension Display Name",
      fieldType: "text",
      fieldDefaultValue: marketplaceListingDetails.marketplaceDisplayName,
      fieldRequired: true,
      fieldHelperText:
        "The display name for the extension used in the Marketplace.",
    },
    {
      fieldName: "marketplaceDescription",
      fieldLabel: "Extension Description",
      fieldType: "textarea",
      fieldDefaultValue: marketplaceListingDetails.marketplaceDescription,
      fieldRequired: true,
      fieldStartingRows: 2,
    },
    {
      fieldName: "marketplaceREADME",
      fieldLabel: "Extension Overview",
      fieldType: "markdown",
      fieldDefaultValue: marketplaceListingDetails.marketplaceREADME,
      fieldRequired: true,
    },
    {
      fieldName: "participantName",
      fieldLabel: "Copilot Chat Participant Name",
      fieldType: "text",
      fieldDefaultValue: marketplaceListingDetails.participantName,
      fieldHelperText:
        "This is how your participant will be invoked, prefixed by @ in Copilot chat.",
      fieldRequired: true,
      fieldValidation: (name: string) => {
        if (name.includes(" ")) {
          return "Participant name cannot contain spaces.";
        }
      },
    },
    {
      fieldName: "participantDescription",
      fieldLabel: "Copilot Chat Participant Description",
      fieldType: "text",
      fieldDefaultValue: marketplaceListingDetails.participantDescription,
      fieldHelperText:
        "This will appear as the placeholder text in Copilot chat while your participant is invoked.",
      fieldRequired: true,
    },
  ];

  async function handleFormSubmit(values: FieldValues) {
    if (extension === undefined) return;
    setMarketplaceListingDetails(values);
    setSubmitButtonLoading(true);

    // Create a new FormData instance
    let formData = new FormData();

    // Append fields to formData (required by form)
    formData.append("description", values.marketplaceDescription!);
    formData.append("display_name", values.marketplaceDisplayName!);
    formData.append("participant_name", values.participantName!);
    formData.append("participant_description", values.participantDescription!);

    let readmeBlob = new Blob([values.marketplaceREADME!], {
      type: "text/markdown",
    });
    let readmeFile = new File([readmeBlob], "readme.md");
    formData.append("readme_file", readmeFile);

    // Append file to formData
    if (values.marketplaceIcon) {
      formData.append("logo_file", values.marketplaceIcon);
    }

    // Call the mutation
    putExtension({
      extensionId: extension.id,
      // @ts-ignore
      extensionUpdate: formData,
    }).then(() => {
      setSubmitButtonLoading(false);
      setEditMode(false);
    });
  }

  useEffect(() => {
    let vals = fieldValues();

    if (extension) {
      vals.marketplaceName = extension.name;
      vals.marketplaceDisplayName = extension.display_name;
      vals.marketplaceDescription = extension.description;
      vals.participantName = extension.participant_name;
      vals.participantDescription = extension.participant_description;
    }

    if (readme) {
      vals.marketplaceREADME = (readme as any).data as string;
    }

    if (logo) {
      vals.marketplaceIcon = (logo as any).data as string;
    }

    setMarketplaceListingDetails(vals);
  }, [extension, readme, logo]);

  useEffect(() => {
    if (extension) {
      refetchExtension();
    }
  }, [extension, refetchExtension]);

  return (
    <PageTemplate
      name={"VS Code Extension"}
      description={
        "Adjust how your Integration Expert extension will show up in VSCode’s marketplace."
      }
      icon={<HubIcon />}
    >
      <Box
        sx={{
          p: 2,
          textAlign: "left",
          border: 1,
          borderColor: "divider",
          borderRadius: 1,
        }}
      >
        {!editMode && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <a
                href={extension?.marketplace_url}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    px: 2,
                    py: 1,
                  }}
                >
                  <OpenInNewIcon />
                  Open in Marketplace
                </Button>
              </a>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setEditMode(true);
                  }}
                  sx={{
                    px: 2,
                    py: 1,
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                  }}
                >
                  <EditIcon />
                  Edit
                </Button>
                <LoadingButton
                  variant="contained"
                  loading={isLoading}
                  disabled={!extension?.pending_publish}
                  onClick={() => {
                    if (extension === undefined) return;
                    publishExtension({
                      extensionId: extension.id,
                      templateName: templates?.template_names.find(
                        (t) => t === "vscode-activity-bar-template.zip",
                      )
                        ? "vscode-activity-bar-template.zip"
                        : templates?.template_names[0]!,
                    })
                      .then((e) => {
                        if (e.hasOwnProperty("error")) {
                          addMessage(
                            "Failed to publish extension.",
                            "error",
                            60,
                          );
                        } else {
                          refetchExtension();
                          addMessage("Published extension!", "success", 60);
                        }
                      })
                      .catch(() =>
                        addMessage("Failed to publish extension.", "error", 60),
                      );
                  }}
                  sx={{
                    px: 2,
                    py: 1,
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                  }}
                >
                  <PublishIcon />
                  Publish Changes
                </LoadingButton>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: 1,
                border: 1,
                borderColor: "divider",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  p: 2,
                  bgcolor: "grey.100",
                  borderRadius: 1 / 1 / 0 / 0,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "72px", height: "72px" }}>
                  <img
                    src={marketplaceListingDetails.marketplaceIcon}
                    alt="Logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                    gap: 1,
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="h1">
                    {marketplaceListingDetails.marketplaceDisplayName}
                  </Typography>
                  <Typography>
                    {marketplaceListingDetails.marketplaceDescription}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Tabs
                  value={tabValue}
                  onChange={(_, newValue) => setTabValue(newValue)}
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <Tab value="Readme" label="Readme" />
                  <Tab
                    value="Copilot Chat Participant"
                    label="Copilot Chat Participant"
                  />
                  <Tab value="Embeddable Links" label="Embeddable Links" />
                </Tabs>
                <Box sx={{ p: 2 }}>
                  {tabValue === "Readme" && (
                    <ReactMarkdown remarkPlugins={[gfm]} className="markdown">
                      {marketplaceListingDetails.marketplaceREADME}
                    </ReactMarkdown>
                  )}
                  {tabValue === "Embeddable Links" && <EmbeddableLinks />}
                  {tabValue === "Copilot Chat Participant" && (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <Typography>
                        This is how your participant will appear when invoked in
                        Copilot chat:
                      </Typography>
                      {marketplaceListingDetails.participantDescription &&
                        marketplaceListingDetails.participantName && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "1px solid",
                              borderColor: "divider",
                              borderRadius: 1,
                              p: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 1,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: "500",
                                  px: 0.5,
                                  py: 0.1,
                                  color: "#2261B8",
                                  bgcolor: "#D5ECFF",
                                  borderRadius: 1,
                                }}
                              >
                                @{marketplaceListingDetails.participantName}
                              </Typography>
                              <Typography sx={{ color: "grey.500" }}>
                                {
                                  marketplaceListingDetails.participantDescription
                                }
                              </Typography>
                            </Box>
                          </Box>
                        )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {editMode && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" sx={{ flexGrow: 1 }}>
                Edit VS Code Extension
              </Typography>
              <IconButton onClick={() => setEditMode(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Form
              fields={formFields}
              handleFormSubmit={handleFormSubmit}
              submitButtonLabel="Save Changes"
              submitButtonLoading={submitButtonLoading}
            />
          </Box>
        )}
      </Box>
    </PageTemplate>
  );
};

export default VSCodeExtension;
