import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { auth } from "../clients/firebase";
import {
  ExtensionsDownloadObjectGetApiArg,
  WebWidgetsDownloadLogoGetApiArg,
} from "./layerApi";
import { RootState } from "./store";

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const firebaseAuth = (await auth.currentUser?.getIdToken()) || "";
  const apiKey = (api.getState() as RootState).customer.api_key;
  return fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LAYER_URL || "",
    prepareHeaders: (headers: Headers) => {
      headers.set("Firebase-Auth", firebaseAuth);
      headers.set("Layer-Api-Key", apiKey || "");
      return headers;
    },
  })(args, api, extraOptions);
};

export const emptyApi = createApi({
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    downloadExtensionObject: builder.query({
      queryFn: async (
        arg: ExtensionsDownloadObjectGetApiArg,
        _,
        __,
        baseQuery,
      ) => {
        const response = await baseQuery({
          url: `extensions/${arg.extensionId}/download/${arg.specialObjectName}`,
          responseHandler: async (response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }

            // Check the content type of the response to determine how to handle it
            const extension = arg.specialObjectName.split(".").pop();
            if (
              extension === "jpg" ||
              extension === "png" ||
              extension === "gif"
            ) {
              // If it's an image, create a Blob URL
              const blob = await response.blob();
              const url = URL.createObjectURL(blob);

              return { data: url };
            } else if (extension === "txt" || extension === "md") {
              // If it's a text file, decode it
              let td = new TextDecoder("utf-8");
              let data = td.decode(await response.arrayBuffer());
              return { data };
            } else {
              throw new Error("Unsupported file type");
            }
          },
        });
        let data = response?.data;
        return { data: data };
      },
    }),
    downloadWebWidgetLogo: builder.query({
      queryFn: async (
        arg: WebWidgetsDownloadLogoGetApiArg,
        _,
        __,
        baseQuery,
      ) => {
        const response = await baseQuery({
          url: `web_widgets/${arg.webWidgetId}/download-logo`,
          responseHandler: async (response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }

            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            return { data: url };
          },
        });
        let data = response?.data;
        return { data: data };
      },
    }),
  }),
});

export const {
  useLazyDownloadExtensionObjectQuery,
  useLazyDownloadWebWidgetLogoQuery,
} = emptyApi;
