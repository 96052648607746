import {Box} from "@mui/material";
import {GridColDef, GridRowId} from "@mui/x-data-grid";
import React, {useMemo} from "react";
import {
    useSourcesCreateWebsiteSourcesPostMutation, WebsiteSourceCreate,
} from "../../../../state/layerApi";
import {LoadingButton} from "@mui/lab";
import {FieldValues} from "react-hook-form";
import GroupedDataGrid from "../../../../shared/GroupedDataGrid";
import {groupSites} from "../../../../shared/siteUtils";

interface iSelectedWebsiteSourcesTable {
    crawledSites: string[];
    handleClose: () => void;
}

const SelectWebsiteSourcesTable = ({
                                       crawledSites,
                                       handleClose,
                                   }: iSelectedWebsiteSourcesTable) => {
    const columns: GridColDef[] = [
        {
            field: "sourceLink",
            headerName: "Source Link",
            type: "string",
            flex: 1,
            resizable: false,
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: (params) => {
                return [
                    <a href={params.row.sourceLink} target="_blank" rel="noreferrer">
                        {params.row.sourceLink}
                    </a>,
                ];
            },
        },
    ];

    const [postWebsiteSources, {isLoading: isWebsitesLoading}] =
        useSourcesCreateWebsiteSourcesPostMutation();

    const [selectedSites, setSelectedSites] = React.useState<GridRowId[]>();

    const groupedCrawledSites = useMemo(() => {
        const groups = groupSites(crawledSites);

        let groupedSiteRows = [];
        let id = 0;
        for (const group in groups) {
            if (group !== "ungrouped") {
                groupedSiteRows.push({
                    id: id++,
                    sourceLink: group.split(",")[group.split(",").length - 1],
                    group,
                    parent: true,
                });
            }
            groups[group].forEach((sourceLink: string) => {
                groupedSiteRows.push({
                    id: id++,
                    sourceLink,
                    group,
                    parent: false,
                });
            });
        }

        return groupedSiteRows;
    }, [crawledSites]);

    function handleAddSources(_: FieldValues) {
        if (selectedSites) {
            const selectedSourceLinks = selectedSites
                .map((id) => {
                    if (!groupedCrawledSites.find((row) => row.id === id)?.parent) {
                        return groupedCrawledSites.find((row) => row.id === id)?.sourceLink;
                    }
                    return undefined;
                })
                .filter(
                    (sourceLink) => sourceLink !== undefined && sourceLink !== null,
                );

            postWebsiteSources({
                sources: selectedSourceLinks.map((sourceLink) => ({url: sourceLink} as WebsiteSourceCreate))
            }).then((response) => {
                if ("data" in response) {
                    handleClose();
                } else if ("error" in response) {
                    console.error(response.error);
                }
            });
        }
    }

    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
            <GroupedDataGrid
                defaultSort={{field: "sourceLink", order: "asc"}}
                maxDepth={3}
                countParents={false}
                columns={columns}
                groupedRows={groupedCrawledSites}
                nestedGroups
                groupSeparator=","
                itemLabel="source"
                updateSelectedRows={setSelectedSites}
            />
            <LoadingButton
                variant="contained"
                disabled={selectedSites ? selectedSites.length === 0 : true}
                loading={isWebsitesLoading}
                onClick={handleAddSources}
            >
                Add {selectedSites ? selectedSites.length : 0} Source
                {selectedSites ? selectedSites.length > 1 && "s" : ""}
            </LoadingButton>
        </Box>
    );
};

export default SelectWebsiteSourcesTable;
